import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Subject } from "rxjs";
import { AuthService } from '../auth/auth.service';
import { Wave } from "../models/wave-list.model";
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { Regions } from '../models/region.model';
import * as $ from 'jquery';
import { Router } from '@angular/router';


@Injectable({ providedIn: "root" })
export class WaveService {
  private waves: Wave[] = [];
  private wavesUpdated = new Subject<{ waves: Wave[] }>();
  TEXT_BACKEND_URL;
  WAVE_BACKEND_URL;
  FOLDER_BACKEND_URL;
  folderName = "";
  invalidMessage;
  invalidMessageChange = new EventEmitter<any>();



  constructor(private http: HttpClient, private router: Router, private authService: AuthService, public dialog: MatDialog) {
    this.WAVE_BACKEND_URL = this.authService.BACKEND_URL + "/api/waves/";
    console.log("wave backend url" + this.WAVE_BACKEND_URL);
    this.TEXT_BACKEND_URL = this.authService.BACKEND_URL + "/api/text/";
  }

  async getServerWaves(userName) {
    console.log("Getting the lsit of audio files from server for", userName);
    const queryParams = `?user=${userName}`;
    return this.http.get<{ message: string, }>(this.TEXT_BACKEND_URL + queryParams).toPromise();
  }

  async updateText(name, text) {
    // console.log("text:",text);
    return this.http.put<{ message: string, ok: string }>(this.TEXT_BACKEND_URL, { textData: text, name: name, status: "accepted",type: this.authService.getIsAdmin()}).toPromise();
  }

  async rejectRecording(name) {
    return this.http.put<{ message: string, ok: string }>(this.TEXT_BACKEND_URL, { name: name,status: "rejected" }).toPromise();
  }

  uploadText(filesToBeUploaded) {
    console.log("filesToBeUploaded:", filesToBeUploaded);

    let textData = new FormData();
    textData.append("email", this.authService.userName);
    var file = filesToBeUploaded[0];
    console.log("file name===" + file.name);
    textData.append("text", file);
    return this.http.post<{ message: string, uploaded: string }>(this.TEXT_BACKEND_URL, textData).toPromise();
  }
}
