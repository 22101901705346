import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { WaveViewerComponent } from './wave-viewer/wave-viewer.component';
import { WaveRecorderComponent } from './wave-recorder/wave-recorder.component';
import { AuthGuard } from "./auth/auth.guard";


const appRoutes: Routes = [
  { path: '', redirectTo: 'auth/login', pathMatch: 'full' },
  { path: 'wave', component: WaveViewerComponent, canActivate: [AuthGuard] },
  { path: 'wave-recorder', component: WaveRecorderComponent, canActivate: [AuthGuard] },
  // { path: "auth", loadChildren: "./auth/auth.module#AuthModule"},
  { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)}
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule { }
