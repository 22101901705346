import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AppRoutingModule } from './app-routing.module';
import { AngularMaterialModule } from "./angular-material.module";
import { AppComponent } from './app.component';
import { WaveViewerComponent } from './wave-viewer/wave-viewer.component';
import { WaveRecorderComponent } from './wave-recorder/wave-recorder.component';
import { RegionService } from './services/region.service';
import { WaveService } from './services/wave-list-service';
import { HeaderComponent } from './header/header.component';
import { AuthInterceptor } from "./auth/auth-interceptor";
import { ErrorInterceptor } from "./error-interceptor";
import { ErrorComponent } from "./error/error.component";
import { AngularSplitModule } from "angular-split";
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AudioRecordingService } from '../app/services/recorder.service';


@NgModule({
  declarations: [
    AppComponent,
    WaveViewerComponent,
    WaveRecorderComponent,
    HeaderComponent,
    ErrorComponent
    
  ],
  exports: [
    MatIconModule
  ],
  imports: [
    MatToolbarModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    CommonModule,
    FormsModule,
    AngularMaterialModule,
    AngularSplitModule, 
    MatMenuModule,
    MatIconModule,
    MatDialogModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
  ],
  providers: [ 
    RegionService, WaveService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    AudioRecordingService
  ],
  bootstrap: [AppComponent],
  entryComponents: [ErrorComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
