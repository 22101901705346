<mat-toolbar style="background-color: cadetblue;">
  <img width="20" alt="Angular Logo" src="assets/images/IS_1.ico" />
  <span class="gap"></span>
  <span class="gap" style="padding-top: 10px;">
    <h5> ISRL Speech Recording Tool</h5>
  </span>

  <span class="example-spacer"></span>
  <span class="example-spacer"></span>
  <span class="example-spacer"></span>
  <span class="example-spacer"></span>
  <span class="example-spacer"></span>
  <span class="example-spacer"></span>
  <span class="example-spacer"></span>
  <span class="example-spacer"></span>
  <div mat-menu-item *ngIf="userIsAuthenticated">
    <button mat-stroked-button (click)="onLogout()" style="height: 30px;">
      <h5>Logout</h5>
    </button>
  </div>
</mat-toolbar>


<div class="height-max" style="width: 98%;">
  <h2 class="name" style="text-align: center;">{{curWaveName}}</h2>
  <h4 class="name" style="text-align: center; padding: 0%;">Status: {{recordingStatus}}</h4>
  <br>

  
  <div style="float: left;margin-left: 30%; margin-top: 5%;">
    <div style="display: flex; justify-content: center; align-items: center; margin-left: 10%;">
      <button mat-button  id="submit" class="btn btn-lg" (click)="goToText()">Go</button>
    </div>
    <div style="display: flex; justify-content: center; align-items: center;">
      <button mat-button id="previousAudio" [disabled]="previousAudios" class="btn btn-lg" (click)="previousAudio()">Previous</button>
      <input matInput placeholder="Input" id="countInputBox" maxlength="5" [(ngModel)]="audioCount" style="margin: 0%;">
      <button mat-button id="nextAudio" [disabled]="nextAudios" class="btn btn-lg" (click)="nextAudio()">Next</button>
    </div>
  </div>

  <div style="margin-right: 20%; margin-top: 5%;">
  <div style="display: flex; justify-content: center; align-items: center">
    <button mat-button [disabled]="isRecording || !isLoaded" id="playPause" class="btn btn-lg" (click)="playPause()"><i class='glyphicon glyphicon-play'></i></button>
  </div>
  <div style="display: flex; justify-content: center; align-items: center; margin-left: 3%;">
    <button mat-button id="saveText" class="btn btn-lg" (click)="saveText()">Save Text</button>
    <button mat-button class="btn btn-textgrid btn-lg start-button inbtn" *ngIf="!isRecording && userIsAuthenticated && !isAdmin" (click)="startRecording()"><mat-icon style="font-size: 30px;" color=primary>mic</mat-icon></button>
    <button mat-button class="btn btn-textgrid btn-lg stop-button inbtn" *ngIf="isRecording && userIsAuthenticated && !isAdmin" (click)="stopRecording()"><mat-icon style="font-size: 30px;" color=primary>mic_off</mat-icon> </button>
    <button mat-button [disabled]="isRecording || !blobUrl" id="saveAudio" class="btn btn-lg" (click)="saveAudio()">Save Audio</button>
  </div>
</div>


  <span class="gap"></span>
  <br>
  <div class="text-box" style="overflow-y: hidden; display: flex; justify-content: center; align-items: center;">
    <textarea class="text" id="text" style="font-size: x-large;"></textarea>
  </div>
</div>
