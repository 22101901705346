<mat-toolbar style="background-color: cadetblue;">
  <!-- <span *ngIf="!fileChosen">Choose the file for Recording</span> -->
  <img width="20" alt="Angular Logo" src="assets/images/IS_1.ico" />
  <span class="gap"></span>
  <span class="gap" style="padding-top: 10px;">
    <h5> ISRL Speech Recording Tool</h5>
  </span>
  <button mat-icon-button data-toggle="tooltip" title="Upload TextFile" style="margin-right:3px;"
    [matMenuTriggerFor]="upload">
    <mat-icon>cloud_upload</mat-icon>
  </button>

  <mat-menu #upload="matMenu">
    <input #fileUpload type="file" (click)="fileUpload.value = null" (change)="importFile($event)" style="display:none"
      accept="text/*">
    <button mat-icon-button data-toggle="tooltip" (click)="fileUpload.click()" title="Upload Text File"
      style=" margin-right:3px;">
      <mat-icon>text_snippet</mat-icon>
    </button>
  </mat-menu>

  <span class="example-spacer"></span>
  <span class="example-spacer"></span>
  <span class="example-spacer"></span>
  <span class="example-spacer"></span>
  <span class="example-spacer"></span>
  <span class="example-spacer"></span>
  <span class="example-spacer"></span>
  <div *ngIf="userIsAuthenticated && isAdmin">
    <a mat-button routerLink="/auth/signup"><h5>Create User</h5></a>
  </div>  
  <span class="example-spacer"></span>
  <div mat-menu-item *ngIf="userIsAuthenticated">
    <button mat-stroked-button (click)="onLogout()" style="height: 30px;">
      <h5>Logout</h5>
    </button>
  </div>
</mat-toolbar>
<div class="height-max" style="width: 98%;">

  <h2 class="name" style="text-align: center;">{{curWaveName}}</h2>
  <h4 class="name" style="text-align: center; padding: 0%;">Status: {{recordingStatus}}</h4>
  <br>
  <div style="margin-left: 1%;" id="waveform"></div>
  <div style="margin-left: 1%;" id="waveform-timeline"></div>
  <input *ngIf="isLoaded" id="slider" type="range" min="50" max="1250" (change)="sliderChange($event)" />
  <br>

  <div style="float: left;margin-left: 30%;">
    <div style="display: flex; justify-content: center; align-items: center; margin-left: 10%;">
      <button mat-button id="submit" class="btn btn-lg" (click)="goToText()">Go</button>
    </div>
    <div style="display: flex; justify-content: center; align-items: center;">
      <button mat-button id="previousAudio" [disabled]="previousAudios" class="btn btn-lg" (click)="previousAudio()">Previous</button>
      <input matInput placeholder="Input" id="countInputBox" maxlength="5" [(ngModel)]="audioCount" style="margin: 0%;">
      <button mat-button id="nextAudio" [disabled]="nextAudios" class="btn btn-lg" (click)="nextAudio()">Next</button>
    </div>
  </div>

  <div style="margin-right: 20%;">
    <div style="display: flex; justify-content: center; align-items: center">
      <button mat-button [disabled]="!isLoaded" id="playPause" class="btn btn-lg" (click)="playPause()"><i class='glyphicon glyphicon-play'></i></button>
    </div>
    <div style="display: flex; justify-content: center; align-items: center; margin-left: 3%;">
      <button mat-button id="saveText" class="btn btn-lg" (click)="saveText()">Save Text / Accept</button>
      <button mat-button id="rejectRecording" class="btn btn-lg" (click)="rejectRecording()">Reject</button>
    </div>
  </div>
  <span class="gap"></span>
  <!-- <div class="current-time">
      <h3>Current Cursor Position: {{this.clickSecond}}</h3>
    </div> -->
  <br>
  <div class="text-box" style="overflow-y: hidden; display: flex; justify-content: center; align-items: center;">
    <textarea class="text" id="text" style="font-size: x-large;"></textarea>
  </div>
  <!-- </as-split-area>
  </as-split> -->
</div>