import { Injectable, EventEmitter, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, Subject, Subscription } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AudioService {
  AUDIO_QUERY_URL = "";
  audioSourceChanged = new EventEmitter<any>();
  checkOut = new EventEmitter<any>();
  productsList = [];

  constructor(private http: HttpClient, private router: Router, private authService: AuthService, @Inject(DOCUMENT) private document: Document) {
    this.AUDIO_QUERY_URL = this.authService.BACKEND_URL + "/api/audio/";
    console.log("AUDIO_QUERY_URL " + this.AUDIO_QUERY_URL);
  }

  setProductsList(productList: any) {
    this.productsList = productList;
  }

  async sendAudio(data, textData) {
    var audioFormData = new FormData();
    console.log("audio title",data.title);
    audioFormData.append('name', data.title);
    audioFormData.append('audio', data.blob);
    audioFormData.append('text', textData);
    return this.http.post<{ message: string, ok: string }>(this.AUDIO_QUERY_URL, audioFormData).toPromise();
  }

  async updateText(name, text) {
    return this.http.put<{ message: string, ok: string }>(this.AUDIO_QUERY_URL, { textData: text, name: name }).toPromise();
  }

}
