import { Component, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import * as WaveSurfer from 'wavesurfer.js';
import * as TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.js';
import * as RegionsPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions.js';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';
import { Wave } from "../models/wave-list.model";
import { Regions } from '../models/region.model';
import { AuthService } from '../auth/auth.service';
import { WaveService } from '../services/wave-list-service';
import { RegionService } from '../services/region.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from "@angular/material/snack-bar";
import { DomSanitizer } from "@angular/platform-browser";
import { AudioRecordingService } from "../services/recorder.service";
import { AudioService } from "../services/audio.service";

@Component({
	selector: 'app-wave-viewer',
	templateUrl: './wave-recorder.component.html',
	styleUrls: ['./wave-recorder.component.css']
})
export class WaveRecorderComponent implements OnInit {

	userName;
	subscription: Subscription;
	private waveSub: Subscription;
	text: any = [];
	curWaveName = "";
	fileChosen = false;
	textFileType = "ooTextFile";
	textFileClass = "TextGrid";
	textGridClass = "IntervalTier";
	textGridName = "Sentence";
	textGridCount;
	eventId = null;
	isMenuOpen = false;
	isLast = false;
	status: string = "";
	event: any;
	curTextFileCont;
	curScroll;
	isLoaded = false;
	regions: Array<Regions>;
	curWavevalue
	// Initializing id & start second for creating regions in wavesurfer instance
	soundSeconds = 0;
	timeline;
	regionId: number = 1;
	startSecond: number = 0;
	clickSecond: number = 0;
	regionColor1 = "rgba(0,255,0,0.3)";
	regionColor2 = "rgba(255, 0, 0, 0.2)";
	regionColor = this.regionColor1;
	text_segment_id = "";
	audioFile;
	public wavesurfer: WaveSurfer;
	textElementId;
	isPlaying = false;
	regionsCount: number;
	regionTextArray: string[] = [];
	value = "horizontal";
	fileList = "";
	userIsAuthenticated = false;
	isAdmin;
	private authListenerSubs: Subscription;
	audioCount = 0;
	audioCountChange = new EventEmitter<Number>();
	previousAudios = false;
	nextAudios = false;
	previousAudioChange = new EventEmitter<boolean>();
	nextAudioChange = new EventEmitter<boolean>();
	curWaveNameChange = new EventEmitter<any>();
	// color = 'white';

	// Audio Recording related
	isRecording = false;
	recordedTime = 0;
	blobUrl;
	audio = new Audio();
	audioData;
	audioSource;
	recordingStatus;

	constructor(private route: ActivatedRoute, private http: HttpClient, private rService: RegionService, public waveService: WaveService, public authService: AuthService, private router: Router, private _snackBar: MatSnackBar, private audioRecordingService: AudioRecordingService, private audioService: AudioService, private sanitizer: DomSanitizer) {

		// Recording Service related subscriptions
		this.audioRecordingService.recordingFailed().subscribe(() => {
			this.isRecording = false;
		});

		this.audioRecordingService.getRecordedTime().subscribe((time) => {
			this.recordedTime = time;
		});

		this.audioRecordingService.getRecordedBlob().subscribe((data) => {
			this.audioData = data;
			this.audioSource = URL.createObjectURL(data.blob);
			this.blobUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data.blob));
			this.isLoaded = true;
		});
	}


	// Recording Service related functions

	startRecording() {
		if (!this.isRecording) {
			this.isRecording = true;
			this.audioRecordingService.startRecording();
		}
	}

	stopRecording() {
		if (this.isRecording) {
			this.isRecording = false;
			this.audioRecordingService.stopRecording(this.curWaveName);
		}
	}

	clearRecordedData() {
		this.blobUrl = null;
	}

	playPause() {
		// console.log("this.isPlaying: ", this.isPlaying);
		this.audio.src = this.audioSource;
		let myInterval;
		if (this.isPlaying == false) {
			this.audio.load();
			this.audio.play();
			this.isPlaying = true;
			// console.log("set isPlaying true inside playPause firt if condition");
			$("#playPause").html("<i class='glyphicon glyphicon-pause'></i>");
			console.log("audio.currentTime:", this.audio.currentTime);
			let audioDuration = this.recordedTime;
			// console.log("audioDuration:", audioDuration);
			myInterval = setInterval(() => {
				if ((parseFloat(this.audio.currentTime.toFixed(2)) >= audioDuration)  ) {
					setTimeout(() => {
						this.audio.pause();
						this.isPlaying = false;
						// console.log("set isPlaying false inside myInterval on auto pause");
						$("#playPause").html("<i class='glyphicon glyphicon-play'></i>");
					}, 500 ) 
					clearInterval(myInterval);
				}
			}, 1000);
		}
		else if (this.isPlaying == true) {
			$("#playPause").html("<i class='glyphicon glyphicon-play'></i> ");
			this.isPlaying = false;
			// console.log("set isPlaying false inside else if condition");
			clearInterval(myInterval);
			this.audio.pause();
		}
	}

	saveAudio() {
		this.audioService.sendAudio(this.audioData, $("#text").val()).then(result => {
			console.log("result:", result);
			this.openSnackBar(result.message + " - " + this.text[this.audioCount].recordingId, "", 4000);
			this.text[this.audioCount].edited = true;
			if(Boolean(result.ok) == true) {
				console.log("result recording , changing status");
				this.text[this.audioCount].status = "recorded";
				this.recordingStatus = "RECORDED";
				this.text[this.audioCount].audioDuration = this.recordedTime;
			}
		})
	}

	ngOnDestroy() {
		this.stopRecording();
		this.authListenerSubs.unsubscribe();
	}

	ngOnInit(): void {

		this.userName = this.authService.getUserName();
		this.waveService.getServerWaves(this.userName).then((response: any) => {
			this.displayWavFiles(response);
		});

		this.userIsAuthenticated = this.authService.getIsAuth();
		// console.log("this.userIsAuthenticated", this.userIsAuthenticated)
		this.userName = this.authService.getUserName();
		// console.log("this.userName", this.userName);
		this.isAdmin = this.authService.getIsAdmin();
		// console.log("this.isAdmin" + this.isAdmin);
		this.authListenerSubs = this.authService
			.getAuthStatusListener()
			.subscribe(isAuthenticated => {
				this.userIsAuthenticated = isAuthenticated;
				this.userName = this.authService.getUserName();
				this.isAdmin = this.authService.getIsAdmin();
			});
	}

	onLogout() {
		this.authService.logout();
	}

	sliderChange(event) {
		var zoomLevel = event.target.value;
		this.wavesurfer.zoom(zoomLevel);
	}

	displayWavFiles(response) {
		console.log("message: ", response.message);
		if (response.ok == false) {
			console.log("Error while fetching Wave files :", response.message);
			//   this.finalResponse = response.nlpResponse;
		} else {
			console.log("Successfully fetched Wave files:", response.message);
			console.log("no. of wave files fetched:", response.text.length);
			this.text = response.text;
			this.curWaveName = response.text[this.audioCount].recordingId;
			this.loadText();
			//   this.processResponse(response.nlpResponse);
		}
	}

	annotateThis() {
		this.status = "";
		this.fileChosen = true;
		const waveLen = this.text.length;
		let count = 0;
		for (let i = 0; i < waveLen; i++) {
			count = count + 1;
			if (this.text[i].recordingNumber == this.audioCount) {
				this.curWaveName = this.text[i].recordingId;
				console.log("audio count in annotate if condition" + this.audioCount);
				this.loadText();
				break;
			} else if ((count == this.text.length) && this.text[i].recordingNumber != this.audioCount) {
				// console.log("i:", i, "and count:", count);
				console.log("Please enter valid number");
				this.openSnackBar("Please enter valid number", "", 4000);
			}
		}
	}

	loadText() {
		$("#playPause").html("<i class='glyphicon glyphicon-play'></i> ");
		this.isPlaying = false;
		this.audio.pause();
		this.clearRecordedData();
		$("textarea").val("");
		if(this.text[this.audioCount].status == "") {
			this.recordingStatus = "NOT RECORDED";	
		} else if (this.text[this.audioCount].status == "recorded") {
			this.recordingStatus = "RECORDED";	
		} else if (this.text[this.audioCount].status == "rejected") {
			this.recordingStatus = "REJECTED";	
		} else {
			this.recordingStatus = "ACCEPTED";	
		}

		// console.log("set isPlaying false inside else if condition");
		const waveLen = this.text.length;
		this.isLoaded = false;
		this.recordedTime = 0;
		if (waveLen - 1 > this.audioCount) {
			this.nextAudios = false;
		} else {
			this.nextAudios = true;
		}

		if (this.audioCount == 0) {
			this.previousAudios = true;
		} else {
			this.previousAudios = false;
		}

		let textFile = this.text[this.audioCount].recordingId;
		// console.log("textFile inside loadText:", textFile);
		if (this.text[this.audioCount].edited == true) {
			textFile = this.authService.BACKEND_URL + "/data/telugu/trans_files/" + textFile + ".txt";
			fetch(textFile)
				.then(response => response.text())
				.then(data => {
					$("textarea").val(data);
				}).catch((err) => {
					console.log("Unable to fetch corresponding text file");
					$("textarea").val("Unable to fetch corresponding text file");
					// document.getElementById('text').innerHTML = "Unable to fetch corresponding text file";
				})
		} else {
			$("textarea").val(this.text[this.audioCount].originalText);
		}

		if (this.text[this.audioCount].status == "recorded" || this.text[this.audioCount].status == "accepted") {
			let audioFile = textFile.replace(".txt", ".wav");
			audioFile = audioFile.replace("/trans_files/", "/audio_files/");
			this.recordedTime = this.text[this.audioCount].audioDuration;
			this.loadAudio(audioFile);
		}
	}

	loadAudio(audioFile) {
		// console.log("Load Audio");
		// console.log("audio count in loadAudio :: " + this.audioCount);
		this.audioSource = audioFile;
		this.audio = new Audio();
		this.isLoaded = true;
		// console.log("this.audioSource:", this.audioSource);
		return;
	}


	previousAudio() {
		// console.log("previous audio");
		this.audioCount--;
		this.curWaveName = this.text[this.audioCount].recordingId;
		// console.log("audio name in previous :: " + this.curWaveName);
		this.loadText();
	}

	nextAudio() {
		// console.log("next Audio");
		this.audioCount++;
		this.curWaveName = this.text[this.audioCount].recordingId;
		// console.log("audio name in next :: " + this.curWaveName)
		this.loadText();
	}

	calcPercent(start, end) {
		var Percentage = ((end - start) / this.soundSeconds * 100).toFixed(3);
		return Percentage;
	}

	importFile(event) {
		// console.log("event.target.files: ", event.target.files);
		var filesToBeUploaded = (event.target as HTMLInputElement).files;
		// var theFiles = event.target.files;

		this.waveService.uploadText(filesToBeUploaded).then((result) => {
			console.log("Upload result: ", result);
			this.openSnackBar(result.message, "", 4000);
		});
	}

	saveText() {
		// console.log("saving text file with", $("#text").val());
		this.waveService.updateText(this.curWaveName, $("#text").val()).then(result => {
			console.log("result:", result.message);
			this.openSnackBar(result.message, "", 4000);
		})
	}

	goToText() {
		// this.audioCount = 
		console.log("go to text:", this.audioCount);
		this.annotateThis();
	}

	openSnackBar(message: string, action: string, duration) {
		this._snackBar.open(message, action, {
			duration: duration,
		});
	}

}
