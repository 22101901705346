import { Component, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import * as WaveSurfer from 'wavesurfer.js';
import * as TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.js';
import * as RegionsPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions.js';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';
import { Wave } from "../models/wave-list.model";
import { Regions } from '../models/region.model';
import { AuthService } from '../auth/auth.service';
import { WaveService } from '../services/wave-list-service';
import { RegionService } from '../services/region.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from "@angular/material/snack-bar";


@Component({
	selector: 'app-wave-viewer',
	templateUrl: './wave-viewer.component.html',
	styleUrls: ['./wave-viewer.component.css']
})
export class WaveViewerComponent implements OnInit {

	userName;
	subscription: Subscription;
	private waveSub: Subscription;
	text: any = [];
	curWaveName = "";
	fileChosen = false;
	textFileType = "ooTextFile";
	textFileClass = "TextGrid";
	textGridClass = "IntervalTier";
	textGridName = "Sentence";
	textGridCount;
	eventId = null;
	isMenuOpen = false;
	isLast = false;
	status: string = "";
	event: any;
	curTextFileCont;
	curScroll;
	isLoaded = false;
	regions: Array<Regions>;
	curWavevalue
	// Initializing id & start second for creating regions in wavesurfer instance
	soundSeconds = 0;
	timeline;
	regionId: number = 1;
	startSecond: number = 0;
	clickSecond: number = 0;
	regionColor1 = "rgba(0,255,0,0.3)";
	regionColor2 = "rgba(255, 0, 0, 0.2)";
	regionColor = this.regionColor1;
	text_segment_id = "";
	audioFile;
	public wavesurfer: WaveSurfer;
	textElementId;
	isPlaying = false;
	regionsCount: number;
	regionTextArray: string[] = [];
	value = "horizontal";
	fileList = "";
	userIsAuthenticated = false;
	isAdmin;
	private authListenerSubs: Subscription;
	audioCount = 0;
	audioCountChange = new EventEmitter<Number>();
	previousAudios = false;
	nextAudios = false;
	previousAudioChange = new EventEmitter<boolean>();
	nextAudioChange = new EventEmitter<boolean>();
	curWaveNameChange = new EventEmitter<any>();
	// color = 'white';

	recordingStatus;

	constructor(private route: ActivatedRoute, private http: HttpClient, private rService: RegionService, public waveService: WaveService, public authService: AuthService, private router: Router, private _snackBar: MatSnackBar) { }

	ngOnInit(): void {

		this.userName = this.authService.getUserName();
		this.waveService.getServerWaves(this.userName).then((response: any) => {
			this.displayWavFiles(response);
		});

		this.userIsAuthenticated = this.authService.getIsAuth();
		console.log("this.userIsAuthenticated", this.userIsAuthenticated)
		this.userName = this.authService.getUserName();
		console.log("this.userName", this.userName);
		this.isAdmin = this.authService.getIsAdmin();
		console.log("this.isAdmin" + this.isAdmin);
		this.authListenerSubs = this.authService
			.getAuthStatusListener()
			.subscribe(isAuthenticated => {
				this.userIsAuthenticated = isAuthenticated;
				this.userName = this.authService.getUserName();
				this.isAdmin = this.authService.getIsAdmin();
			});

		// we are creating waveform holder
		this.wavesurfer = WaveSurfer.create({
			height: 170,
			container: '#waveform',
			scrollParent: false,
			waveColor: '#3fb0ac',
			progressColor: '#547980',
			fillParent: true,  //<-- Initially to fill wave full width
			plugins: [
				TimelinePlugin.create({
					container: "#waveform-timeline"
				}),
				RegionsPlugin.create({})
			],
			backend: 'MediaElement'
		});

		this.wavesurfer.on('error', (status) => {
			this.status = status.name;
			console.log("load error status:", status);
			if (this.status.indexOf("AbortError") == -1) {
				//console.log("this.status: "+this.status);
				// this.removeRegions();
				this.isLoaded = false;
				$("#waveform").css("display", "none");
				$("#waveform-timeline").css("display", "none");
			}

		});

		this.wavesurfer.on("loading", function (progress) {
			console.log("loading audio in wavesurfer");
		});

		this.wavesurfer.on('ready', () => {
			console.log("-------- inside on ready -------");
			this.soundSeconds = this.wavesurfer.getDuration();
			this.soundSeconds = +this.soundSeconds.toFixed(3);
			this.startSecond = +this.startSecond.toFixed(3);
			console.log("this.startSecond", this.startSecond, "this.soundSeconds", this.soundSeconds);

			// this.createExistRegions();

			//creating text field before creating any regions

			this.wavesurfer.params.minPxPerSec = 50;
			var minPxPerSec = this.wavesurfer.params.minPxPerSec;

			var totalPixels = $("#waveform").innerWidth();
			var pxPerSec = totalPixels / this.soundSeconds;
			pxPerSec = +pxPerSec.toFixed(3);
			this.wavesurfer.zoom(pxPerSec);
			$('#textgrid-resize').css("width", (totalPixels) + "px");

			$('#slider').attr("min", pxPerSec);
			$('#slider').val(pxPerSec);
			$("#waveform").children("wave").css("border-style", "groove");
		});

		this.wavesurfer.on('zoom', (pxPerSec) => {
			var onZoom = this.soundSeconds * pxPerSec;
			onZoom = +onZoom.toFixed(3);
			$('#textgrid-resize').css("width", (onZoom) + "px");
		});

		this.wavesurfer.on('scroll', (scrollEvent) => {
			var scroll = scrollEvent.target.scrollLeft;
			this.curScroll = scroll;
			var elems = $('.textgrid-segment');
			var len = elems.length;
			for (var i = 0; i < len; i++) {
				$("#" + elems[i].id).css("left", -scroll);
			}
		});

		$(window).resize(() => {
			var totalPixels = $("#waveform").innerWidth();
			var pxPerSec = totalPixels / this.soundSeconds;
			pxPerSec = +pxPerSec.toFixed(3);
			this.wavesurfer.zoom(pxPerSec);
			$('#textgrid-resize').css("width", (totalPixels) + "px");
			$('#slider').attr("min", pxPerSec);
			$('#slider').val(pxPerSec);
		});

		// this.wavesurfer.on('region-dblclick', () => {
		// 	// this.createRegion();
		// 	this.clickSecond = this.wavesurfer.getCurrentTime();
		// 	this.clickSecond = +this.clickSecond.toFixed(3);
		// });

		// this.wavesurfer.on('click',() => {
		// 	this.clickSecond = this.wavesurfer.getCurrentTime();
		// 	this.clickSecond = +this.clickSecond.toFixed(3);
		// })
	}

	onLogout() {
		this.authService.logout();
	}

	sliderChange(event) {
		var zoomLevel = event.target.value;
		this.wavesurfer.zoom(zoomLevel);
	}

	displayWavFiles(response) {
		console.log("message: ", response.message);
		if (response.ok == false) {
			console.log("Error while fetching Wave files :", response.message);
			//   this.finalResponse = response.nlpResponse;
		} else {
			console.log("Successfully fetched Wave files:", response.message);
			console.log("no. of wave files fetched:", response.text.length);
			this.text = response.text;
			this.curWaveName = response.text[this.audioCount].recordingId;
			this.loadText();
			//   this.processResponse(response.nlpResponse);
		}
	}

	annotateThis() {
		this.status = "";
		this.fileChosen = true;
		const waveLen = this.text.length;
		let count = 0;
		for (let i = 0; i < waveLen; i++) {
			count = count + 1;
			if (this.text[i].recordingNumber == this.audioCount) {
				this.curWaveName = this.text[i].recordingId;
				console.log("audio count in annotate if condition" + this.audioCount);
				this.loadText();
				break;
			} else if ((count == this.text.length) && this.text[i].recordingNumber != this.audioCount) {
				 console.log("i:",i ,"and count:",count);
				console.log("Please enter valid number");
				this.openSnackBar("Please enter valid number", "", 4000);
			}
		}
	}

	loadText() {
		const waveLen = this.text.length;
		if (waveLen - 1 > this.audioCount) {
			this.nextAudios = false;
		} else {
			this.nextAudios = true;
		}

		if (this.audioCount == 0) {
			this.previousAudios = true;
		} else {
			this.previousAudios = false;
		}

		if(this.text[this.audioCount].status == "") {
			this.recordingStatus = "NOT RECORDED";	
		} else if (this.text[this.audioCount].status == "recorded") {
			this.recordingStatus = "RECORDED";	
		} else if (this.text[this.audioCount].status == "rejected") {
			this.recordingStatus = "REJECTED";	
		} else {
			this.recordingStatus = "ACCEPTED";	
		}

		let textFile = this.text[this.audioCount].recordingId;
		console.log("textFile inside loadText:", textFile);
		if(this.text[this.audioCount].edited == true) {
			textFile = this.authService.BACKEND_URL + "/data/telugu/trans_files/" + textFile + ".txt";
			fetch(textFile)
			.then(response => response.text())
			.then(data => {
				$("textarea").val(data);
			}).catch((err) => {
				console.log("Unable to fetch corresponding text file");
				$("textarea").val("Unable to fetch corresponding text file");
				// document.getElementById('text').innerHTML = "Unable to fetch corresponding text file";
			})
		} else {
			$("textarea").val(this.text[this.audioCount].originalText);
		}

		if(this.text[this.audioCount].status == "recorded" || this.text[this.audioCount].status == "accepted") {
			let audioFile = textFile.replace( ".txt", ".wav");
			audioFile = audioFile.replace("/trans_files/", "/audio_files/");
			this.loadAudio(audioFile);
		} else {
			this.isLoaded = false;
			$("#waveform").css("display", "none");
			$("#waveform-timeline").css("display", "none");
		}
	}

	rejectRecording() {
		this.waveService.rejectRecording(this.curWaveName).then(result => {
			console.log("result:", result.message);
			this.openSnackBar(result.message, "", 4000);
			this.recordingStatus = "REJECTED";	
			this.text[this.audioCount].status = "rejected";
		})
	}

	loadAudio(audioFile) {
		console.log("Load Audio");
		// this.removeRegions();
		this.clickSecond = 0;
		console.log("audio count in loadAudio :: " + this.audioCount)
		this.isLoaded = true;

		$("#waveform").css("display", "block");
		$("#waveform-timeline").css("display", "block");
		this.wavesurfer.load(audioFile);
		return;
	}


	previousAudio() {
		console.log("previous audio");
		this.audioCount--;
		this.curWaveName = this.text[this.audioCount].recordingId;
		console.log("audio name in previous :: " + this.curWaveName);
		this.loadText();
	}

	nextAudio() {
		console.log("next Audio");
		this.audioCount++;
		this.curWaveName = this.text[this.audioCount].recordingId;
		console.log("audio name in next :: " + this.curWaveName)
		this.loadText();
	}

	playPause() {
		let currentTime = this.wavesurfer.getCurrentTime();
		currentTime = currentTime.toFixed(3);
		if (this.isPlaying == false) {
			const eleRegions = this.rService.getRegions();
			var eleRegionsCount = eleRegions.length;
			for (let i = 0; i < eleRegionsCount; i++) {
				this.wavesurfer.regions.list[eleRegions[i].regionId].loop = false;
			}
			this.wavesurfer.play();
			$("#playPause").html("<i class='glyphicon glyphicon-pause'></i>");
		}
		else if (this.isPlaying == true) {
			this.wavesurfer.pause();
			this.clickSecond = this.wavesurfer.getCurrentTime();
			this.clickSecond = +this.clickSecond.toFixed(3);
			$("#playPause").html("<i class='glyphicon glyphicon-play'></i> ");
		}
		this.isPlaying = !this.isPlaying;
	}

	
	calcPercent(start, end) {
		var Percentage = ((end - start) / this.soundSeconds * 100).toFixed(3);
		return Percentage;
	}

	importFile(event) {
		console.log("event.target.files: ", event.target.files);
		var filesToBeUploaded = (event.target as HTMLInputElement).files;
		// var theFiles = event.target.files;

		this.waveService.uploadText(filesToBeUploaded).then((result) => {
			console.log("Upload result: ", result);
			this.openSnackBar(result.message, "", 4000);
		});
	}

	saveText() {
		// console.log("saving text file with", $("#text").val());
		this.waveService.updateText(this.curWaveName, $("#text").val()).then(result => {
			console.log("result:", result.message);
			this.openSnackBar(result.message, "", 4000);
			this.recordingStatus = "ACCEPTED";	
			this.text[this.audioCount].status = "accepted";
		})
	}

	goToText() {
		// this.audioCount = 
		console.log("go to text:", this.audioCount);
		this.annotateThis();
	}

	openSnackBar(message: string, action: string, duration) {
		this._snackBar.open(message, action, {
			duration: duration,
		});
	}

	// PlayInLoop() {
	// 	this.closeMenu();
	// 	const eleRegions = this.rService.getRegions();
	// 	var eleRegionsCount = eleRegions.length;
	// 	let currentStart;
	// 	let currentEnd;
	// 	for (let i = 0; i < eleRegionsCount; i++) {
	// 		this.wavesurfer.regions.list[eleRegions[i].regionId].loop = true;
	// 		if (this.eventId == eleRegions[i].regionId) {
	// 			currentStart = eleRegions[i].startTime;
	// 			currentEnd = eleRegions[i].endTime;
	// 		}
	// 	}
	// 	this.wavesurfer.play(currentStart, currentEnd);
	// 	$("#playPause").html("<i class='glyphicon glyphicon-pause'></i> Pause");
	// 	this.isPlaying = !this.isPlaying;
	// }



	// createRegion() {
	// 	this.clickSecond = this.wavesurfer.getCurrentTime();
	// 	this.clickSecond = +this.clickSecond.toFixed(3);
	// 	console.log("inside createRegion with this.clickSecond", this.clickSecond);
		// this.regions = this.rService.getRegions();
		// this.regionsCount = this.regions.length;
		// var newRegion: Regions;

		// var getTextElems = $('.textgrid-segment');
		// var getTextElemsLen = getTextElems.length;
		// const textRegions = this.rService.getRegions();
		// var textRegionsCount = textRegions.length;
		// if (getTextElemsLen == textRegionsCount) {
		// 	for (var i = 0; i < textRegionsCount; i++) {
		// 		var textRegId = textRegions[i].regionId;
		// 		textRegions[i].regionText = $("#text_segment_" + textRegId).val();
		// 	}
		// }

		// for (let i = 0; i < this.regionsCount; i++) {
		// 	const curRegion = this.regions[i];
		// 	if (this.clickSecond == curRegion.startTime) {
		// 		break;
		// 	}
		// 	else if (this.clickSecond > curRegion.startTime && this.clickSecond < curRegion.endTime) {
		// 		var regionPercentage = this.calcPercent(curRegion.startTime, this.clickSecond);
		// 		newRegion = new Regions(curRegion.regionId, curRegion.startTime, this.clickSecond, regionPercentage, curRegion.regionText);
		// 		this.rService.addRegion(curRegion.regionId - 1, newRegion);
		// 		curRegion.startTime = this.clickSecond;

		// 		curRegion.regionText = "";
		// 		regionPercentage = this.calcPercent(curRegion.startTime, curRegion.endTime);
		// 		curRegion.regionId = curRegion.regionId + 1;
		// 		curRegion.regionPercentage = regionPercentage;
		// 	}
		// 	else {
		// 		if (this.clickSecond < curRegion.startTime) {
		// 			curRegion.regionId = curRegion.regionId + 1;
		// 			regionPercentage = this.calcPercent(curRegion.startTime, curRegion.endTime);
		// 			curRegion.regionPercentage = regionPercentage;
		// 		}
		// 	}
		// }
		// this.renderRegions();
	// }

	// createExistRegions() {
	// 	const waveLen = this.text.length;
	// 	for (let i = 0; i < waveLen; i++) {
	// 		console.log("Inside createExistingRegions: this.text[i].recordingName", this.text[i].recordingName, "this.curWaveName", this.curWaveName, "with this.text[i].state", this.text[i].state);
	// 		if (this.text[i].recordingName == this.curWaveName) {
	// 			// get annotation text from PouchDb
	// 			let textArray = this.text[i].annotated_speech;
	// 			console.log("textArray inside createExistingRegions", textArray);
	// 			var curTextRegion: Regions;
	// 			if (textArray.length > 0) {
	// 				// storing the regions data
	// 				for (let i = 0; i < textArray.length; i++) {
	// 					if (textArray.length == 1 && textArray[i].endTime == 0) {
	// 						textArray[i].endTime = this.soundSeconds;
	// 						console.log("textArray[i].endTime when endTime is 0", textArray[i].endTime);
	// 					}

	// 					if (textArray.length == 1 && textArray[i].regionText == "") {
	// 						textArray[i].regionText = "Text for the audio";
	// 					}
	// 					let regionPercent = this.calcPercent(parseFloat(textArray[i].startTime), parseFloat(textArray[i].endTime));
	// 					curTextRegion = new Regions(parseFloat(textArray[i].regionId), parseFloat(textArray[i].startTime), parseFloat(textArray[i].endTime), regionPercent, textArray[i].regionText);
	// 					this.rService.addRegion(textArray[i].regionId - 1, curTextRegion);
	// 				}
	// 				this.renderRegions();
	// 			} else {
	// 				console.log("completed ", this.text[i].completed);
	// 				// Adding initial html text element if no corresponding text file
	// 				$('#text_tier').empty();
	// 				this.rService.deleteRegions();
	// 				this.wavesurfer.clearRegions();
	// 				this.text_segment_id = "text_segment_" + this.regionId;
	// 				var initialPercentage = this.calcPercent(this.startSecond, this.soundSeconds);
	// 				var initialTextElement =
	// 					"<input class=\"textgrid-segment  border-color0\" autocomplete=\"on\" style=\"width:" + initialPercentage + "%; font-size: 22px !important\" type=\"text\" value=\"Text for the audio\" id=\"" + this.text_segment_id + "\">";
	// 				$("#text_tier").append(initialTextElement);

	// 				// Adding initial region if no corresponding text file
	// 				curTextRegion = new Regions(this.regionId, this.startSecond, this.soundSeconds, initialPercentage, "Text for the audio");
	// 				this.rService.addRegion(this.regionId - 1, curTextRegion);
	// 				this.wavesurfer.addRegion({
	// 					id: this.regionId,
	// 					start: this.startSecond,
	// 					end: this.soundSeconds,
	// 					drag: false,
	// 					resize: false,
	// 					color: this.regionColor1,
	// 					loop: true
	// 				});
	// 			}
	// 			return;
	// 		}
	// 	}
	// }

	// renderRegions() {
	// 	this.wavesurfer.clearRegions();
	// 	$('#text_tier').empty();
	// 	this.regions = this.rService.getRegions();
	// 	this.regionsCount = this.regions.length;
	// 	this.regionTextArray = [];
	// 	console.log("this.regions", this.regions);
	// 	for (let i = 0; i < this.regionsCount; i++) {
	// 		const editedRegion = this.regions[i];
	// 		console.log("editedRegion", editedRegion);
	// 		if (this.regionColor == this.regionColor1) {
	// 			this.regionColor = this.regionColor2;
	// 		}
	// 		else {
	// 			this.regionColor = this.regionColor1;
	// 		}

	// 		this.wavesurfer.addRegion({
	// 			id: editedRegion.regionId,
	// 			start: editedRegion.startTime,
	// 			end: editedRegion.endTime,
	// 			drag: false,
	// 			resize: false,
	// 			color: this.regionColor,
	// 			loop: true
	// 		});

	// 		var regionTextId = "text_segment_" + editedRegion.regionId;

	// 		var eleText = "<input class=\"textgrid-segment  border-color0\" autocomplete=\"on\" style=\"width: " + editedRegion.regionPercentage + "%; font-size: 22px !important\" type=\"text\" value=\"" + editedRegion.regionText + "\" id=\"" + regionTextId + "\">";
	// 		this.regionTextArray[editedRegion.regionId - 1] = eleText;
	// 	}

	// 	for (let i = 0; i < this.regionTextArray.length; i++) {
	// 		$("#text_tier").append(this.regionTextArray[i]);
	// 	}
	// 	const eleRegions = this.rService.getRegions();
	// 	var eleRegionsCount = eleRegions.length;

	// 	for (let i = 0; i < eleRegionsCount; i++) {
	// 		var eleTextId = "text_segment_" + eleRegions[i].regionId;
	// 		$("#" + eleTextId).css("width", eleRegions[i].regionPercentage + "%");
	// 		$("#" + eleTextId).css("left", -this.curScroll);
	// 	}
	// }

	// removeRegions() {
	// 	// Clearing Regions & Removing Text Segments
	// 	this.rService.deleteRegions();
	// 	$('#text_tier').empty();
	// 	this.wavesurfer.clearRegions();

	// 	this.regionId = 1;
	// 	this.startSecond = 0;
	// 	this.text_segment_id = "";
	// 	this.isPlaying = false;
	// }

	// goToRegionStart(event) {
	// 	const textId = event.target.id;
	// 	const texNumber = textId.charAt(textId.length - 1);
	// 	const textRegions = this.rService.getRegions();
	// 	var textRegionsCount = textRegions.length;
	// 	for (var i = 0; i < textRegionsCount; i++) {
	// 		if (texNumber == textRegions[i].regionId) {
	// 			var textStart = (textRegions[i].startTime / this.soundSeconds).toFixed(3);
	// 			const textStartNum = +textStart;
	// 			this.wavesurfer.seekAndCenter(textStartNum);
	// 		}
	// 	}
	// }

	// openMenu(event) {
	// 	this.isMenuOpen = true;
	// 	this.eventId = Number(event.target.attributes["data-id"].value);
	// 	event.preventDefault();

	// 	// enable & disable menu Buttons
	// 	this.regions = this.rService.getRegions();
	// 	this.regionsCount = this.regions.length;
	// 	if (this.eventId - 1 <= 0) {
	// 		$("#mergeBefore").prop("disabled", true);
	// 	}
	// 	else {
	// 		$("#mergeBefore").prop("disabled", false);
	// 	}
	// 	if (this.eventId + 1 <= this.regionsCount) {
	// 		$("#mergeAfter").prop("disabled", false);
	// 	}
	// 	else {
	// 		$("#mergeAfter").prop("disabled", true);
	// 	}

	// 	$("#menu").css("display", "block");
	// 	$("#menu").css("left", event.clientX + "px");
	// 	$("#menu").css("top", event.clientY + "px");
	// }

	// merge(event) {

	// 	this.closeMenu();
	// 	let eventType = event.target.attributes.id.nodeValue;

	// 	//getting the existing text elements value from html
	// 	var getTextElems = $('.textgrid-segment');
	// 	var getTextElemsLen = getTextElems.length;
	// 	const textRegions = this.rService.getRegions();
	// 	var textRegionsCount = textRegions.length;
	// 	if (getTextElemsLen == textRegionsCount) {
	// 		for (var i = 0; i < textRegionsCount; i++) {
	// 			var textRegId = textRegions[i].regionId;
	// 			textRegions[i].regionText = $("#text_segment_" + textRegId).val();
	// 		}
	// 	}

	// 	this.regions = this.rService.getRegions();
	// 	this.regionsCount = this.regions.length;

	// 	for (let i = this.regionsCount - 1; i >= 0; i--) {
	// 		if (this.regions[i].regionId == this.eventId) {
	// 			if (eventType == "mergeAfter") {
	// 				//merging with the region after
	// 				this.regions[i].regionText = this.regions[i].regionText + " " + this.regions[i + 1].regionText;
	// 				this.regions[i].endTime = this.regions[i + 1].endTime;
	// 				this.regions[i].regionPercentage = this.calcPercent(this.regions[i].startTime, this.regions[i].endTime);
	// 				this.rService.deleteRegion(i + 1);
	// 			}
	// 			else if (eventType == "mergeBefore") {
	// 				//merging with the region after
	// 				this.regions[i].regionText = this.regions[i - 1].regionText + " " + this.regions[i].regionText;
	// 				this.regions[i].startTime = this.regions[i - 1].startTime;
	// 				this.regions[i].regionPercentage = this.calcPercent(this.regions[i].startTime, this.regions[i].endTime);
	// 				this.regions[i].regionId = this.regions[i].regionId - 1;
	// 				this.rService.deleteRegion(i - 1);
	// 			}
	// 		}
	// 		else if (this.regions[i].regionId >= this.eventId + 1) {

	// 			this.regions[i].regionId = this.regions[i].regionId - 1;
	// 		}
	// 	}
	// 	this.renderRegions();
	// }

	// closeMenu() {
	// 	if (this.isMenuOpen == true) {
	// 		this.isMenuOpen = false;
	// 		$("#menu").css("display", "none");
	// 	}
	// }

}
