<mat-toolbar *ngIf="!userIsAuthenticated" class="header">
  <img width="20" alt="Angular Logo" src="assets/images/IS_1.ico" />
  <span class="gap"></span>
  <span class="gap">
    <h5> ISRL Speech Recording Tool</h5>
  </span>
  <span class="filler"></span>

  <div *ngIf="!userIsAuthenticated">
    <a mat-button routerLink="/auth/login">
      <h5>Login</h5>
    </a>
  </div>


</mat-toolbar>